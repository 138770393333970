import { default as verificationxxVClvGANLMeta } from "/app/apps/site/layers/Job/pages/application/verification.vue?macro=true";
import { default as forgotten_45passwordRpZVzXqozwMeta } from "/app/apps/site/pages/auth/forgotten-password.vue?macro=true";
import { default as indexvVp5q835c9Meta } from "/app/apps/site/pages/auth/index.vue?macro=true";
import { default as login9aL5YFykZgMeta } from "/app/apps/site/pages/auth/login.vue?macro=true";
import { default as logout3ToXs4iiNrMeta } from "/app/apps/site/pages/auth/logout.vue?macro=true";
import { default as verify_45emailRvb47T6gzOMeta } from "/app/apps/site/pages/auth/verify-email.vue?macro=true";
import { default as verifyCovoxa0j2NMeta } from "/app/apps/site/pages/auth/verify.vue?macro=true";
import { default as authorJak2L365A0Meta } from "/app/apps/site/pages/calculator/author.vue?macro=true";
import { default as salaryTyS2Dt5Jx6Meta } from "/app/apps/site/pages/calculator/salary.vue?macro=true";
import { default as serviceEkzLfqk1G4Meta } from "/app/apps/site/pages/calculator/service.vue?macro=true";
import { default as configkj7mpiY1EoMeta } from "/app/apps/site/pages/debug/config.vue?macro=true";
import { default as routesSQU0r3BH8UMeta } from "/app/apps/site/pages/debug/routes.vue?macro=true";
import { default as _91category_93S43E2l0ypAMeta } from "/app/apps/site/pages/faq/[category].vue?macro=true";
import { default as indexyh7btIRDb0Meta } from "/app/apps/site/pages/faq/index.vue?macro=true";
import { default as indexhfvhpQJmdsMeta } from "/app/apps/site/pages/index/index.vue?macro=true";
import { default as _91slug_93WUsuQsVLwcMeta } from "/app/apps/site/pages/info/[slug].vue?macro=true";
import { default as apply_45external_45countdowno4dScsCepgMeta } from "/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external-countdown.vue?macro=true";
import { default as apply_45external_45formffRxqQBZErMeta } from "/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external-form.vue?macro=true";
import { default as apply_45externalZSWdh18JwnMeta } from "/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external.vue?macro=true";
import { default as applyckcqptWrm5Meta } from "/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply.vue?macro=true";
import { default as detailsUwtPC3FJB9Meta } from "/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/details.vue?macro=true";
import { default as _91jobId_93r9pWVUJYhIMeta } from "/app/apps/site/layers/Job/pages/job/[jobId].vue?macro=true";
import { default as my_45profile_45preview09icPF9X4kMeta } from "/app/apps/site/layers/MyProfile/pages/my-profile-preview.vue?macro=true";
import { default as my_45profileOqnUgpRMHiMeta } from "/app/apps/site/layers/MyProfile/pages/my-profile.vue?macro=true";
import { default as applicationsSeLeQ1SiZOMeta } from "/app/apps/site/pages/my/applications.vue?macro=true";
import { default as documentspLgNA6kZOSMeta } from "/app/apps/site/pages/my/documents.vue?macro=true";
import { default as favorite_45jobstsEDq5mqXaMeta } from "/app/apps/site/pages/my/favorite-jobs.vue?macro=true";
import { default as notificationsCc6VMGb4P0Meta } from "/app/apps/site/pages/my/notifications.vue?macro=true";
import { default as settingsLPMr7BiiGHMeta } from "/app/apps/site/pages/my/settings.vue?macro=true";
import { default as indexFlTcufSab2Meta } from "/app/apps/site/pages/search/index.vue?macro=true";
export default [
  {
    name: "application-verification___hr",
    path: "/application/verification",
    component: () => import("/app/apps/site/layers/Job/pages/application/verification.vue")
  },
  {
    name: "application-verification___en",
    path: "/en/application/verification",
    component: () => import("/app/apps/site/layers/Job/pages/application/verification.vue")
  },
  {
    name: "auth-forgotten-password___hr",
    path: "/auth/forgotten-password",
    meta: forgotten_45passwordRpZVzXqozwMeta || {},
    component: () => import("/app/apps/site/pages/auth/forgotten-password.vue")
  },
  {
    name: "auth-forgotten-password___en",
    path: "/en/auth/forgotten-password",
    meta: forgotten_45passwordRpZVzXqozwMeta || {},
    component: () => import("/app/apps/site/pages/auth/forgotten-password.vue")
  },
  {
    name: "auth___hr",
    path: "/auth",
    meta: indexvVp5q835c9Meta || {},
    component: () => import("/app/apps/site/pages/auth/index.vue")
  },
  {
    name: "auth___en",
    path: "/en/auth",
    meta: indexvVp5q835c9Meta || {},
    component: () => import("/app/apps/site/pages/auth/index.vue")
  },
  {
    name: "auth-login___hr",
    path: "/prijava",
    meta: login9aL5YFykZgMeta || {},
    component: () => import("/app/apps/site/pages/auth/login.vue")
  },
  {
    name: "auth-login___en",
    path: "/en/login",
    meta: login9aL5YFykZgMeta || {},
    component: () => import("/app/apps/site/pages/auth/login.vue")
  },
  {
    name: "auth-logout___hr",
    path: "/odjava",
    component: () => import("/app/apps/site/pages/auth/logout.vue")
  },
  {
    name: "auth-logout___en",
    path: "/en/logout",
    component: () => import("/app/apps/site/pages/auth/logout.vue")
  },
  {
    name: "auth-verify-email___hr",
    path: "/auth/verify-email",
    component: () => import("/app/apps/site/pages/auth/verify-email.vue")
  },
  {
    name: "auth-verify-email___en",
    path: "/en/auth/verify-email",
    component: () => import("/app/apps/site/pages/auth/verify-email.vue")
  },
  {
    name: "auth-verify___hr",
    path: "/auth/verify",
    component: () => import("/app/apps/site/pages/auth/verify.vue")
  },
  {
    name: "auth-verify___en",
    path: "/en/auth/verify",
    component: () => import("/app/apps/site/pages/auth/verify.vue")
  },
  {
    name: "calculator-author___hr",
    path: "/calculator/author",
    component: () => import("/app/apps/site/pages/calculator/author.vue")
  },
  {
    name: "calculator-author___en",
    path: "/en/calculator/royalty-contract",
    component: () => import("/app/apps/site/pages/calculator/author.vue")
  },
  {
    name: "calculator-salary___hr",
    path: "/calculator/salary",
    component: () => import("/app/apps/site/pages/calculator/salary.vue")
  },
  {
    name: "calculator-salary___en",
    path: "/en/calculator/salary",
    component: () => import("/app/apps/site/pages/calculator/salary.vue")
  },
  {
    name: "calculator-service___hr",
    path: "/calculator/service",
    component: () => import("/app/apps/site/pages/calculator/service.vue")
  },
  {
    name: "calculator-service___en",
    path: "/en/calculator/service-contract",
    component: () => import("/app/apps/site/pages/calculator/service.vue")
  },
  {
    name: "debug-config___hr",
    path: "/debug/config",
    component: () => import("/app/apps/site/pages/debug/config.vue")
  },
  {
    name: "debug-config___en",
    path: "/en/debug/config",
    component: () => import("/app/apps/site/pages/debug/config.vue")
  },
  {
    name: "debug-routes___hr",
    path: "/debug/routes",
    component: () => import("/app/apps/site/pages/debug/routes.vue")
  },
  {
    name: "debug-routes___en",
    path: "/en/debug/routes",
    component: () => import("/app/apps/site/pages/debug/routes.vue")
  },
  {
    name: "faq-category___hr",
    path: "/cesta-pitanja/:category()",
    component: () => import("/app/apps/site/pages/faq/[category].vue")
  },
  {
    name: "faq-category___en",
    path: "/en/faq/:category()",
    component: () => import("/app/apps/site/pages/faq/[category].vue")
  },
  {
    name: "faq___hr",
    path: "/cesta-pitanja",
    component: () => import("/app/apps/site/pages/faq/index.vue")
  },
  {
    name: "faq___en",
    path: "/en/faq",
    component: () => import("/app/apps/site/pages/faq/index.vue")
  },
  {
    name: "index___hr",
    path: "/",
    component: () => import("/app/apps/site/pages/index/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/app/apps/site/pages/index/index.vue")
  },
  {
    name: "info-slug___hr",
    path: "/info/:slug()",
    component: () => import("/app/apps/site/pages/info/[slug].vue")
  },
  {
    name: "info-slug___en",
    path: "/en/info/:slug()",
    component: () => import("/app/apps/site/pages/info/[slug].vue")
  },
  {
    name: "job-jobId___hr",
    path: "/posao/:jobId()",
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId].vue"),
    children: [
  {
    name: "job-jobId-slug-apply-external-countdown___hr",
    path: ":slug()/apply-external-countdown",
    meta: apply_45external_45countdowno4dScsCepgMeta || {},
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external-countdown.vue")
  },
  {
    name: "job-jobId-slug-apply-external-form___hr",
    path: ":slug()/apply-external-form",
    meta: apply_45external_45formffRxqQBZErMeta || {},
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external-form.vue")
  },
  {
    name: "job-jobId-slug-apply-external___hr",
    path: ":slug()/apply-external",
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external.vue")
  },
  {
    name: "job-jobId-slug-apply___hr",
    path: ":slug()/prijava",
    meta: applyckcqptWrm5Meta || {},
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply.vue")
  },
  {
    name: "job-jobId-slug-details___hr",
    path: ":slug()",
    meta: detailsUwtPC3FJB9Meta || {},
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/details.vue")
  }
]
  },
  {
    name: "job-jobId___en",
    path: "/en/job/:jobId()",
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId].vue"),
    children: [
  {
    name: "job-jobId-slug-apply-external-countdown___en",
    path: ":slug()/apply-external-countdown",
    meta: apply_45external_45countdowno4dScsCepgMeta || {},
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external-countdown.vue")
  },
  {
    name: "job-jobId-slug-apply-external-form___en",
    path: ":slug()/apply-external-form",
    meta: apply_45external_45formffRxqQBZErMeta || {},
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external-form.vue")
  },
  {
    name: "job-jobId-slug-apply-external___en",
    path: ":slug()/apply-external",
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external.vue")
  },
  {
    name: "job-jobId-slug-apply___en",
    path: ":slug()/apply",
    meta: applyckcqptWrm5Meta || {},
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply.vue")
  },
  {
    name: "job-jobId-slug-details___en",
    path: ":slug()",
    meta: detailsUwtPC3FJB9Meta || {},
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/details.vue")
  }
]
  },
  {
    name: "my-profile-preview___hr",
    path: "/my-profile-preview",
    meta: my_45profile_45preview09icPF9X4kMeta || {},
    component: () => import("/app/apps/site/layers/MyProfile/pages/my-profile-preview.vue")
  },
  {
    name: "my-profile-preview___en",
    path: "/en/my-profile-preview",
    meta: my_45profile_45preview09icPF9X4kMeta || {},
    component: () => import("/app/apps/site/layers/MyProfile/pages/my-profile-preview.vue")
  },
  {
    name: "my-profile___hr",
    path: "/moj-profil",
    meta: my_45profileOqnUgpRMHiMeta || {},
    component: () => import("/app/apps/site/layers/MyProfile/pages/my-profile.vue")
  },
  {
    name: "my-profile___en",
    path: "/en/my-profile",
    meta: my_45profileOqnUgpRMHiMeta || {},
    component: () => import("/app/apps/site/layers/MyProfile/pages/my-profile.vue")
  },
  {
    name: "my-applications___hr",
    path: "/moj-profil/prijave",
    meta: applicationsSeLeQ1SiZOMeta || {},
    component: () => import("/app/apps/site/pages/my/applications.vue")
  },
  {
    name: "my-applications___en",
    path: "/en/my-profile/applications",
    meta: applicationsSeLeQ1SiZOMeta || {},
    component: () => import("/app/apps/site/pages/my/applications.vue")
  },
  {
    name: "my-documents___hr",
    path: "/moj-profil/dokumenti",
    meta: documentspLgNA6kZOSMeta || {},
    component: () => import("/app/apps/site/pages/my/documents.vue")
  },
  {
    name: "my-documents___en",
    path: "/en/my-profile/documents",
    meta: documentspLgNA6kZOSMeta || {},
    component: () => import("/app/apps/site/pages/my/documents.vue")
  },
  {
    name: "my-favorite-jobs___hr",
    path: "/moj-profil/spremljeni-poslovi",
    meta: favorite_45jobstsEDq5mqXaMeta || {},
    component: () => import("/app/apps/site/pages/my/favorite-jobs.vue")
  },
  {
    name: "my-favorite-jobs___en",
    path: "/en/my-profile/saved-jobs",
    meta: favorite_45jobstsEDq5mqXaMeta || {},
    component: () => import("/app/apps/site/pages/my/favorite-jobs.vue")
  },
  {
    name: "my-notifications___hr",
    path: "/moj-profil/obavijesti",
    meta: notificationsCc6VMGb4P0Meta || {},
    component: () => import("/app/apps/site/pages/my/notifications.vue")
  },
  {
    name: "my-notifications___en",
    path: "/en/my-profile/notifications",
    meta: notificationsCc6VMGb4P0Meta || {},
    component: () => import("/app/apps/site/pages/my/notifications.vue")
  },
  {
    name: "my-settings___hr",
    path: "/moj-profil/postavke",
    meta: settingsLPMr7BiiGHMeta || {},
    component: () => import("/app/apps/site/pages/my/settings.vue")
  },
  {
    name: "my-settings___en",
    path: "/en/my-profile/settings",
    meta: settingsLPMr7BiiGHMeta || {},
    component: () => import("/app/apps/site/pages/my/settings.vue")
  },
  {
    name: "search___hr",
    path: "/pretraga-poslova",
    meta: indexFlTcufSab2Meta || {},
    component: () => import("/app/apps/site/pages/search/index.vue")
  },
  {
    name: "search___en",
    path: "/en/job-search",
    meta: indexFlTcufSab2Meta || {},
    component: () => import("/app/apps/site/pages/search/index.vue")
  }
]